import { template as template_57d41eeca89c47e8890d192a3c47cf82 } from "@ember/template-compiler";
const FKText = template_57d41eeca89c47e8890d192a3c47cf82(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
