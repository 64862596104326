import { template as template_7e294eaf692a4c6892a4a3ad9002fd0b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_7e294eaf692a4c6892a4a3ad9002fd0b(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
